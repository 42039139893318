import React, { useMemo }  from 'react';
import { MenuItem, ListSubheader } from '@mui/material';
import MultiSelectDropDown from 'Components/MultiSelectDropDown';
import { StringUtil, UserHelper } from 'Util/Helpers';
import { orderBy } from 'lodash';
import { DealsListFilterSettings } from './DealsListFilterSettings';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

function resolveInitialValue(name) {
    const lastFilter = DealsListFilterSettings.getLastUsedFilter();

    if (!StringUtil.isNullOrEmpty(lastFilter)) {
        return lastFilter[name];
    }

    return undefined;
}

function selectedItemLabel(itemsArr, value, t) {
    if (StringUtil.isNullOrEmpty(itemsArr) || itemsArr.length === 0) {
        return;
    }

    const found = itemsArr.find(i => StringUtil.isEqual(i.SecureId, value));

    return UserHelper.getFullName(found);
}

function createTpStaffUsersSelector()
{
    return createSelector(
        state => state.tpStaffUsers.list,
        x => x
    );
}


function renderMenuList(itemsArr, getStyle, renderItem, t) {
    if (!itemsArr) {
        return null;
    }

    const rendered = [];

    const sorted = orderBy(itemsArr, ['Department'], ['asc']);

    let lastDept = '';

    for (const item of sorted) {
        const dept = item.Department;

        if (!StringUtil.isEqual(dept, lastDept)) {
            rendered.push(<ListSubheader key={dept}>{t(dept)}</ListSubheader>);
            lastDept = dept;
        }

        rendered.push(<MenuItem key={item.SecureId} value={item.SecureId} style={getStyle(item)}>{UserHelper.getFullName(item)}</MenuItem>);
    }

    return rendered;
}

function TPUsersDL(props) {
    const { name } = props;

    const selectTpStaffUsers = useMemo(
        createTpStaffUsersSelector,
        []
    );

    let itemsList = useSelector(x => selectTpStaffUsers(x));

    itemsList = orderBy(itemsList, ['FirstName'], ['asc']);

    function initSelected() {
        return resolveInitialValue(name);
    }

    return (
        <MultiSelectDropDown {...props} loadItems={() => itemsList} renderMenuList={renderMenuList} selectedItemLabel={selectedItemLabel} resolveInitialValue={initSelected} />
    );
}

export default TPUsersDL;
