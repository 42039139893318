import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import { green, grey, red } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import ErrorSnackbar from 'Components/ErrorSnackbar';
import { Question, SectionHeader } from 'Components/Questionary';
import { PropertyTypeEnum } from 'Model/PolicyOrder';
import React, { Fragment, useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createSelector } from 'reselect';
import { IdGenerator, StringUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { QuestionaryHelper } from 'Util/QuestionaryHelper';
import Conditional from './Conditional';
import { ProjectApiClient } from './ProjectApiClient';
import ProjectContainer from './ProjectContainer';
import Exceptions from './Sections/Exceptions';
import Properties from './Sections/Properties';
import * as ProjectActions from './State/ProjectActions';
import * as ProjectThunks from './State/ProjectThunks';
import { UnderwritingManager } from './../Model/UserRoles';
import * as UserActions from './../User/State/UserActions';
import { landRegistryOffices } from 'Model/LandRegistryOffices';

const useStyles = makeStyles(theme => ({
    form: {
        width: '100%', // Fix IE 11 issue.
        padding: theme.spacing(3)
    },
    welcomeText: {
        color: grey[700],
        fontSize: '1rem'
    }
}));

const useStylesBusyInd = makeStyles(theme => ({
    btnBusyInd: {
        color: green[500]
    },
}));

const useStylesSaveButton = makeStyles(theme => ({
    submit: {
        margin: theme.spacing(3, 0, 2)
    }
}));

function createErrorMsgSelector() {
    return createSelector(
        state => state.project.errorMsg,
        x => x
    );
}

function ErrorBlock(props) {
    const selectErrorMsg = useMemo(
        createErrorMsgSelector,
        []
    );

    const errorMsg = useSelector(x => selectErrorMsg(x));

    if (StringUtil.isNullOrEmpty(errorMsg))
        return null;

    return (
        <ErrorSnackbar variant="error" message={errorMsg} style={{ width: '100%' }} />
    );
}

function createIsBusySelector() {
    return createSelector(
        state => state.project.isBusy,
        x => x
    );
}

function SmallBusyInd(props) {
    const classes = useStylesBusyInd();

    const selectIsBusy = useMemo(
        createIsBusySelector,
        []
    );

    const isBusy = useSelector(x => selectIsBusy(x));

    if (isBusy) {
        return (
            <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress className={classes.btnBusyInd} />
            </Box>
        );
    }

    return null;
}

function SaveButton(props) {
    const classes = useStylesSaveButton();

    const selectIsBusy = useMemo(
        createIsBusySelector,
        []
    );

    const isBusy = useSelector(x => selectIsBusy(x));

    return (
        <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={isBusy}
        >
            Save
        </Button>
    );
}

const logger = new Logger(ProjectView.name);

const questionary = {
    Questions: [
        { Id: "ProjectCode", Type: "FreeForm", Text: "Project Code" },
        { Id: "Name", Type: "FreeForm", Text: "Project Name" },
        { Id: "Province", Type: "Province", Text: "Province" },
        { Id: "City", Type: "FreeForm", Text: "City" },

        {
            Id: "Status", Type: "DropDownSelection", Text: "Status",
            Options: [
                { Id: "Active", Label: "Active", Primary: true },
                { Id: "Inactive", Label: "Inactive" },
                { Id: "Deleted", Label: "Deleted" },
                { Id: "UWPending", Label: "UW Pending" },
                { Id: "NoClosingWihoutAuthorization", Label: "No Closing Without Authorization" }
            ]
        },

        // { Id: "LastRegisteredInstrumentNumber", Type: "FreeForm", Text: "Last Registered Instrument Number" },
        // { Id: "LastInstrumentRegistrationDate", Type: "Date", Text: "Last Instrument Registration Date" },
        {
            Id: "PropertyType", Type: "SingleSelection", Text: "Property Type",
            Options: [
                { Id: PropertyTypeEnum.SingleFamily, Label: "Single Family", Primary: true },
                { Id: PropertyTypeEnum.MultiUnit, Label: "Multi-Unit (2-6 units)" },
                { Id: PropertyTypeEnum.Condominium, Label: "Condominium (with or without parking/locker units)" }
            ]
        },
        { Id: "POTL", Type: "YesNo", Text: "POTL", Options: [{ Id: "yes", Label: "Yes", Primary: true }, { Id: "no", Label: "no" }] },
        { Id: "PINPrefix", Type: "FreeForm", Text: "PIN Prefix" },
        { Id: "IsNewCondoSelect", Type: "YesNo", Text: "Is it New Condo Select", Options: [{ Id: "yes", Label: "Yes", Primary: true }, { Id: "no", Label: "no" }] },

        { Id: "LegalDescription", Type: "MultiPart", Text: "Legal Description", NestingPath: "PropertyLegalDescription" },
        { Id: "Plan", Type: "FreeForm", Text: "Plan", NestingPath: "PropertyLegalDescription" },
        { Id: "ReferencePlan", Type: "FreeForm", Text: "Reference Plan", NestingPath: "PropertyLegalDescription" },
        {
            Id: "Type", Type: "DropDownSelection", Text: "Type", NestingPath: "PropertyLegalDescription",
            Options: [
                { Id: "Lot", Label: "Lot", Primary: true },
                { Id: "PartOfLot", Label: "Part of Lot" },
                { Id: "Block", Label: "Block" },
                { Id: "PartOfBlock", Label: "Part of Block" },
                { Id: "Dwelling", Label: "Dwelling" },
                { Id: "Parking", Label: "Parking" },
                { Id: "Locker", Label: "Locker" }
            ]
        },

        { Id: "BoundaryFrom", Type: "Numeric", Text: "Boundary From", NestingPath: "PropertyLegalDescription" },
        { Id: "BoundaryTo", Type: "Numeric", Text: "Boundary To", NestingPath: "PropertyLegalDescription" },
        { Id: "SubDivisionFrom", Type: "FreeForm", Text: "SubDivision From", NestingPath: "PropertyLegalDescription" },
        { Id: "SubDivisionTo", Type: "FreeForm", Text: "SubDivision To", NestingPath: "PropertyLegalDescription" },
        {
            Id: "IsPOTL", Type: "SingleSelectionCheckBox", Text: "POTL", NestingPath: "PropertyLegalDescription",
            Options: [
                { Id: "yes", Label: "", Primary: true }
            ]
        },

        { Id: "CommonElementsCondoNumber", Type: "FreeForm", Text: "Common Elements Condominium Number" },
        { Id: "CommonElementsPIN", Type: "FreeForm", Text: "Common Elements PIN" },
        {
            Id: "LandRegistryOffice", Type: "AutoCompleteDropDownSelection", Text: "Land Registry Office",
            "Options": [...landRegistryOffices]
        },
        { Id: "RegistrySystem", Type: "SingleSelection", Text: "Registry System", Options: [{ Id: "LandRegistry", Label: "Land Registry", Primary: true }, { Id: "LandTitles", Label: "Land Titles" }] },
        { Id: "AdditionalInformation", Type: "FreeForm", Text: "Additional Information" },
        { Id: "ClosingProcedure", Type: "FreeForm", Text: "Closing Procedure" },

        //TODO ICollection<string> AdditionalDocuments

        //TODO IEnumerable<ProjectException> Exceptions
        {
            Id: "ExceptionAppliesTo", Type: "DropDownSelection", Text: "Exception Applies To", NestingPath: "Exceptions",
            Options: [
                { Id: "WholeProject", Label: "Whole Project", Primary: true },
                { Id: "RangeOfProperties", Label: "Range Of Properties" }
            ]
        },
        { Id: "Code", Type: "FreeForm", Text: "Exception Code", NestingPath: "Exceptions" },
        { Id: "Topic", Type: "FreeForm", Text: "Topic", NestingPath: "Exceptions" },
        { Id: "Description", Type: "FreeForm", Text: "Description", NestingPath: "Exceptions" },
        {
            Id: "FlagForManualReview", Type: "SingleSelectionCheckBox", Text: "Flag for Manual Review", NestingPath: "Exceptions",
            Options: [
                { Id: "yes", Label: "These group of properties may not close without TitlePLUS authorization.", Primary: true }
            ]
        },

        {
            Id: "PolicyType", Type: "DropDownSelection", Text: "PolicyType", NestingPath: "Exceptions",
            Options: [
                { Id: "owner_policy_only", Label: "Owner Policy", Primary: true },
                { Id: "lender_policy_only", Label: "Lender Policy" },
                { Id: "owner_and_lender", Label: "Owner & Lender Policy" }
            ]
        },

        { Id: "Range", Type: "MultiPart", Text: "Range", NestingPath: "ExceptionRange" },
        { Id: "ExceptionId", Type: "FreeForm", Text: "ExceptionId", NestingPath: "ExceptionRange" },
        { Id: "Plan", Type: "FreeForm", Text: "Plan", NestingPath: "ExceptionRange" },
        { Id: "ReferencePlan", Type: "FreeForm", Text: "Reference Plan", NestingPath: "ExceptionRange" },
        {
            Id: "Type", Type: "DropDownSelection", Text: "Type", NestingPath: "ExceptionRange",
            Options: [
                { Id: "Lot", Label: "Lot", Primary: true },
                { Id: "PartOfLot", Label: "Part of Lot" },
                { Id: "Block", Label: "Block" },
                { Id: "PartOfBlock", Label: "Part of Block" },
                { Id: "Dwelling", Label: "Dwelling" },
                { Id: "Parking", Label: "Parking" },
                { Id: "Locker", Label: "Locker" }
            ]
        },
        { Id: "BoundaryFrom", Type: "Numeric", Text: "Boundary From", NestingPath: "ExceptionRange" },
        { Id: "BoundaryTo", Type: "Numeric", Text: "Boundary To", NestingPath: "ExceptionRange" },
        { Id: "SubDivisionFrom", Type: "FreeForm", Text: "SubDivision From", NestingPath: "ExceptionRange" },
        { Id: "SubDivisionTo", Type: "FreeForm", Text: "SubDivision To", NestingPath: "ExceptionRange" },
    ]
};

function ProjectView(props) {
    const classes = useStyles();

    const selectUserInfo = useMemo(
        () => UserActions.createUserInfoSelector(),
        []
    );

    const userInfo = useSelector(x => selectUserInfo(x));

    const dispatch = useDispatch();

    const hist = useHistory();

    const [initLoadData, setInitLoadData] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            loading: true,
            answersMap: null
        }
    );

    let isAdmin = true;

    const validEmails = [
        "anna.colacitti@lawpro.ca",
        "denyse.alfonzo@lawpro.ca",
        "lucy.barber@lawpro.ca",
        "leo.law@lawpro.ca",
        "alex.krakowitz@lawpro.ca",
        "brendan.fagan@lawpro.ca",
        "sini.petkovic+tp@gmail.com"];

    function format(projectInfo) {
        if (projectInfo.Exceptions && projectInfo.Exceptions.length > 0) {
            for (const ex of projectInfo.Exceptions) {
                ex["Id"] = IdGenerator.NewId();
                if (ex.Properties && ex.Properties.length > 0) {
                    var properties = ex.Properties.map(x => ({
                        ...x,
                        "ExceptionId": ex.Id,
                        "Id": IdGenerator.NewId()
                    }));

                    if (!projectInfo.ExceptionRange || projectInfo.ExceptionRange.length === 0)
                        projectInfo["ExceptionRange"] = [];

                    projectInfo.ExceptionRange.push(...properties);
                }
            }
        }

        if (projectInfo.PropertyLegalDescription && projectInfo.PropertyLegalDescription.length > 0)
            for (const property of projectInfo.PropertyLegalDescription)
                property["Id"] = IdGenerator.NewId();

        return projectInfo;
    }

    let secureIdToLoad = ProjectThunks.getProjectIdFromQueryString();

    useEffect(() => {
        let isCancelled = false;

        function onUnload() {
            isCancelled = true;
        }

        async function loadProject() {
            if (StringUtil.isNullOrEmpty(secureIdToLoad)) {
                logger.debug('loadDeal: deal # is not provided. assuming new deal.');

                secureIdToLoad = 'new';
            }

            let projectInfo = {};

            if (!StringUtil.isEqual(secureIdToLoad, 'new')) {
                const result = await ProjectApiClient.load(secureIdToLoad);

                if (result.hasError) {
                    if (!isCancelled) {
                        setInitLoadData({ loading: false, loadingErrorMessage: result.errorMessage });
                    }

                    return onUnload;
                }

                let resp = result.data.NewHomeProject;

                projectInfo = format(resp);                
            }           

            if (!isCancelled) {
                const answersMap = new Map();

                // inputs must be set before setLoading(false)...
                for (const q of questionary.Questions) {
                    QuestionaryHelper.setAnswerInitialValue(projectInfo, q, answersMap, isCancelled);
                }

                dispatch({ type: ProjectActions.set_new_project.type, payload: projectInfo });

                if (StringUtil.isEqual(secureIdToLoad, 'new')) {
                    dispatchDefaultClosingInstruction();
                }

                setInitLoadData({
                    loading: false,
                    answersMap
                });
            }
        }

        logger.debug(loadProject.name + ' useEffect');

        loadProject();

        /////////////////////////////////////////
        // const answersMap = new Map();

        // // inputs must be set before setLoading(false)...
        // for (const q of questionary.Questions) {
        //     QuestionaryHelper.setAnswerInitialValue(projectInfo, q, answersMap, isCancelled);
        // }

        // dispatch({ type: ProjectActions.set_new_project.type, payload: projectInfo });

        // setInitLoadData({
        //     loading: false,
        //     answersMap
        // });
        /////////////////////////////////////////

        return onUnload;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!userInfo)
        return null;

    if (!StringUtil.isEqual(userInfo.Role, UnderwritingManager))
        return null;

    if (!validEmails.includes(userInfo.Email))
        isAdmin = false;

    // console.debug(`isAdmin: ${isAdmin}`);
    // console.debug(`userInfo: ${JSON.stringify(userInfo)}`);

    const defaultClosingInstruction = `On Closing:  The last registered instrument no. is NUMBER registered on DATE.
If you discover any discrepancies, contact TitlePLUS immediately at (416) 598-5899 or 1-800-410-1013 and DO NOT CLOSE until you are authorized to do so by a representative of the TitlePLUS Department.
There is no need to call for any condominium by-laws registered subsequent to the above, even if they are not described in the vendor's lawyer's title advice statement/title memorandum.
NOTE:  THIS IS A COMMERCIAL UNIT AND IT DOES NOT QUALIFY UNDER THE RESIDENTIAL POLICY, PLEASE CONTACT A TITLEPLUS UNDERWRITING COUNSEL TO DISCUSS OBTAINING A COMMERCIAL POLICY.
NOTE:  Lien registered as DR1982467 in favour of J & S Mechanical Incorporated is registered against your unit. Contact TitlePLUS immediately and DO NOT CLOSE until you are authorized to do so by a representative of the TitlePLUS Department.
There is no need to call for any of the following documents registered subsequent to the above:
1.           discharges of mortgages or other encumbrances;
2.           postponements;
3.           part-lot control exemption by-laws;
4.           reference plans;
5.           parcelization transfers from builder to itself; and
6.           transfers of part of a lot or block to other purchasers, and mortgages given by these purchasers.`

    function dispatchDefaultClosingInstruction() {
            const fieldName = "ClosingProcedure";
            const fieldValue = defaultClosingInstruction;
            dispatch({ type: ProjectActions.setProjectInput.type, payload: { fieldName, fieldValue } });
    }

    const handleInputChange = (event) => {
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        dispatch({ type: ProjectActions.setProjectInput.type, payload: { fieldName, fieldValue } });
        dispatch({ type: ProjectActions.clearValidationError.type, payload: { fieldName } });
    }

    function getInitialAnswer(fieldName) {
        if (!initLoadData || !initLoadData.answersMap || !fieldName) {
            return null;
        }

        let a = initLoadData.answersMap.get(fieldName);

        if (StringUtil.isNullOrEmpty(secureIdToLoad))
            if (StringUtil.isEqual(fieldName, 'ClosingProcedure'))
                a = defaultClosingInstruction;

        return a;
    }

    function inputProps(name) {
        const p = {
            id: name,
            name: name,
            defaultValue: getInitialAnswer(name) || '',
            onChange: handleInputChange,
            readOnly: false
        };

        return p;
    }

    const questionParams = {
        questionary,
        inputProps,
        handleInputChange,
        fieldStateResolver: (x) => x.project.formState
    };

    function save(event) {
        event.preventDefault(); // importan otherwise the form will try to post
        dispatch(ProjectThunks.save(initLoadData.answersMap, hist));
        setTimeout(() => {
            hist.push(`/admin/projects/list`);
        }, 5000);
    }

    if (initLoadData.loading) {
        // do not render until we loaded all we needed
        return null;
    }

    const conditionalProps = {
        indexer: questionParams.indexer,
        getInitialAnswer: getInitialAnswer
    };

    return (<Fragment>
        <ProjectContainer>
            <Typography component="h1" variant="h4" style={{ color: grey[800] }}>New Home Project</Typography>
            {!isAdmin && <Typography component="h3" style={{ color: red[800] }}>View Access Only</Typography>}
            <form className={classes.form} noValidate onSubmit={save}>
                <Grid container spacing={2}>
                    <Question qId="ProjectCode" {...questionParams} txtWidth={50} />
                    <Question qId="Name" {...questionParams} txtWidth={100} />
                    <Question qId="Status" {...questionParams} txtWidth={25} />
                    <Question qId="PropertyType" {...questionParams} />

                    <Conditional upon="PropertyType" when={(x) => StringUtil.isEqual(x, PropertyTypeEnum.Condominium)} {...conditionalProps}>
                        <Question qId="IsNewCondoSelect" {...questionParams} />
                    </Conditional>

                    <Question qId="POTL" {...questionParams} />
                    <Question qId="PINPrefix" {...questionParams} txtWidth={25} />
                    <Conditional upon="POTL" when={(x) => StringUtil.isYes(x)} {...conditionalProps}>
                        <Question qId="CommonElementsPIN" {...questionParams} txtWidth={25} />
                        <Question qId="CommonElementsCondoNumber" {...questionParams} txtWidth={25} />
                    </Conditional>

                    <Question qId="Province" {...questionParams} txtWidth={25} />
                    <Question qId="City" {...questionParams} txtWidth={25} />
                    <Question qId="LandRegistryOffice" allowFreeText {...questionParams} txtWidth={25} />
                    <Question qId="RegistrySystem" {...questionParams} />

                    {/* <Question qId="LastRegisteredInstrumentNumber" {...questionParams} txtWidth={25} />
                    <Question qId="LastInstrumentRegistrationDate" {...questionParams} txtWidth={25} /> */}

                    <SectionHeader section="legalDescription" title="Legal Description" collapsible>
                        <Properties
                            getInitialAnswer={getInitialAnswer}
                            questionParams={questionParams}
                        />
                    </SectionHeader>

                    <SectionHeader section="exceptions" title="Exceptions" collapsible>
                        <Exceptions
                            getInitialAnswer={getInitialAnswer}
                            questionParams={questionParams} />
                    </SectionHeader>
                    <Question qId="AdditionalInformation" {...questionParams} multiline optional />
                    <Question qId="ClosingProcedure" {...questionParams} multiline optional />
                    <ErrorBlock />
                    <SaveButton />
                </Grid>
                <SmallBusyInd />
            </form>
        </ProjectContainer>
    </Fragment>
    );
}

export default ProjectView;
