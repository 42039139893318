import { has } from 'lodash';
import { ProjectApiClient } from 'NewHomeProjects/ProjectApiClient';
import { StringUtil, UriUtil } from 'Util/Helpers';
import Logger from 'Util/Logger';
import { ValidationHelper } from 'Util/ValidationHelper';
import * as ProjectActions from './ProjectActions';

const logger = new Logger("ProjectThunks");

export function getProjectIdFromQueryString() {
    const searchParams = new URLSearchParams(window.location.search);
    const secureId = searchParams.get("id");

    return secureId;
}

export function setSecureIdUrlParam(secureId) {
    if (StringUtil.isNullOrEmpty(secureId)) {
        return;
    }

    UriUtil.replaceUrlParam('id', secureId);
}

export function save(answersMap, hist) {
    return async function (dispatch, getState) {
        dispatch({ type: ProjectActions.clearError.type });
        dispatch({ type: ProjectActions.setBusy.type, payload: true });

        const currState = getState();

        const inputs = { ...currState.project.userInput };

        answersMap.forEach(function (value, key) {
            if (!has(inputs, key)) {
                inputs[key] = value;
            }
        });

        const secureId = getProjectIdFromQueryString();

        if (!StringUtil.isNullEmptyOrWhiteSpace(secureId))
            inputs["SecureId"] = secureId;

        const resp = await ProjectApiClient.save(inputs);

        dispatch({ type: ProjectActions.setBusy.type, payload: false });

        if (resp.hasError) {
            dispatch({ type: ProjectActions.setError.type, payload: resp.errorMessage });

            if (!resp.hasValidationResult) {
                return;
            }
        }

        const respData = resp.data;

        const validationMap = ValidationHelper.getValidationMap(respData);

        dispatch({ type: ProjectActions.setValidationResult.type, payload: { validationMap } });

        if (ValidationHelper.hasErrors(respData)) {
            setTimeout(function () {
                ValidationHelper.scrollToFirstError(respData);
            }, 1000);
            
            dispatch({ type: ProjectActions.setError.type, payload: "Unable to save project. Are you sure you have permission to do this?" });

            return;
        }

        if (respData.Saved) {
            logger.info('Successfully saved project');

            // dispatch({ type: ProjectActions.setJustSaved.type, payload: true });
        }

        const projectInfo = respData.NewHomeProject;

        setSecureIdUrlParam(projectInfo.SecureId);

        // dispatch({ type: ProjectActions.setActionMessage.type, payload: { type: respData.Status, message: respData.StatusDescription } });
    }
}
